import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import { userInfoState } from 'recoil/userStore';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import Select from 'react-select';
import useAxios from 'hooks/useAxios';
import ImageInfo from 'components/popup/imageInfo';

const getDatesStartToLast = (startDate, lastDate) => {
  var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
  if (!(regex.test(startDate) && regex.test(lastDate)))
    return 'Not Date Format';
  var result = [];
  var curDate = new Date(startDate);
  while (curDate <= new Date(lastDate)) {
    result.push(curDate.toISOString().split('T')[0]);
    curDate.setMonth(curDate.getMonth() + 1);
  }
  return result;
};

const getFirstDate = (date) => {
  let y = new Date(date).getFullYear();
  let m = new Date(date).getMonth();
  return new Date(y, m, 1);
};

const CheckListPage = (props) => {
  const userID = props.user_id;
  const userInfo = useRecoilValue(userInfoState);
  const [daysOption, setDaysOption] = useState([]);
  const { apiUrls, axiosFetch } = useAxios();
  const [checklistInfo, setChecklistInfo] = useState([]);
  const [selectedDays, setSelectedDays] = useState();
  const [checklist, setChecklist] = useState([]);
  const [selectedCheckList, setSelectedCheckList] = useState();
  const [physicals, setPhysicals] = useState();
  const [services, setServices] = useState();
  const [comment, setComment] = useState('');
  const [customerComment, setCustomerComment] = useState();
  const [images, setImages] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState();

  const baseURL =
    process.env.NODE_ENV === 'development' ? 'http://localhost:8917/' : `/`;

  const getContractCheckListInfo = () => {
    axiosFetch({
      method: 'post',
      url: apiUrls.getContractCheckListInfo,
      requestConfig: {
        user_id: userID,
      },
    })
      .then((res) => {
        if (res.success) {
          res.checklistInfo?.map((el) => {
            el.isChecked = false;
          });
          setChecklistInfo(res.checklistInfo);

          if (res.contractInfo) {
            let days = getDatesStartToLast(
              moment(
                getFirstDate(res.contractInfo?.contract_start_date)
              ).format('YYYY-MM-DD'),
              moment().endOf('month').format('YYYY-MM-DD')
            );

            setDaysOption(() =>
              days?.map((day) => ({
                value: day,
                label: day
                  .substr(0, 4)
                  .concat('년')
                  .concat(' ')
                  .concat(day.substr(5, 2))
                  .concat('월'),
              }))
            );
          }
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log('[ERROR] getContractCheckListInfo : ', err);
      });
  };

  const selectedDayHandler = (data) => {
    let startDate = moment(data).startOf('month').format('YYYY-MM-DD');
    let endDate = moment(data)
      .endOf('month')
      .add(1, 'day')
      .format('YYYY-MM-DD');
    let list = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    setSelectedDays(data);
    setChecklist(list);
    setSelectedCheckList(list[list.length - 1]);
  };

  const changeCheckList = (e, item) => {
    e.preventDefault();
    setSelectedCheckList(item);
  };

  const checkDayOfWeek = (date) => {
    let day = moment(date).day();
    if (day === 0) return '일';
    else if (day === 1) return '월';
    else if (day === 2) return '화';
    else if (day === 3) return '수';
    else if (day === 4) return '목';
    else if (day === 5) return '금';
    else if (day === 6) return '토';
  };

  const saveCheckList = () => {
    if (
      customerComment === '' ||
      customerComment === null ||
      customerComment === undefined
    ) {
      alert('고객님의 의견을 입력해주세요.');
      return;
    }
    axiosFetch({
      method: 'post',
      url: apiUrls.updateCommentCheckList,
      requestConfig: {
        check_id: selectedCheckList.check_id,
        reply_comment: customerComment,
      },
    })
      .then((res) => {
        alert(res.message);
        setCustomerComment('');
      })
      .catch((err) => {
        console.log('[ERROR] updateCommentCheckList : ', err);
      });
  };

  const openModal = (e, item) => {
    e.preventDefault();
    setModalOpen(true);
    setSelectedImage(item);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (userID !== undefined) {
      getContractCheckListInfo();
    }
  }, []);

  useEffect(() => {
    if (userID !== undefined) {
      getContractCheckListInfo();
    }
  }, [userID]);

  useEffect(() => {
    selectedDayHandler(moment().startOf('month').format('YYYY-MM-DD'));
  }, [checklistInfo]);

  useEffect(() => {
    if (!selectedCheckList) return;
    setChecklist((checklist) =>
      checklist?.map((el) =>
        el.check_id === selectedCheckList.check_id
          ? { ...el, isChecked: true }
          : { ...el, isChecked: false }
      )
    );

    let physicalArray = selectedCheckList?.check_physical_name?.split(',');
    let physicalValue = selectedCheckList?.check_physicals?.split(',');
    setPhysicals(() =>
      physicalArray.map((physical, index) => ({
        name: physical,
        checked: physicalValue[index] === '1' ? true : false,
      }))
    );

    let serviceArray = selectedCheckList?.check_service_name?.split(',');
    let serviceValue = selectedCheckList?.check_services?.split(',');
    setServices(() =>
      serviceArray.map((service, index) => ({
        name: service,
        checked: serviceValue[index] === '1' ? true : false,
      }))
    );

    let imageArray = selectedCheckList?.image_files?.split(',');
    setImages(() =>
      imageArray.map((image) => ({
        fileName: image,
      }))
    );
    setComment(selectedCheckList?.check_comment);
    setCustomerComment(
      selectedCheckList?.reply_comment === null
        ? ''
        : selectedCheckList?.reply_comment
    );
  }, [selectedCheckList]);

  return (
    <>
      <div className="content">
        <div className="title">
          <div>
            <h1>ZIM(Zone Interactive Manager)</h1>
            <p>
              클리니션의 ZIM은 고객서비스관리 강화, 업무생산성 향상, 운영안정성,
              추가 제안 제시를 달성합니다.
            </p>
          </div>
        </div>
        <div className="checklist-area webpart-area">
          <div className="webpart-item webpart-calendar">
            <div className="webpart-select">
              <h2>방문일정</h2>
              <div className="select-area">
                <Select
                  id="select01"
                  options={daysOption}
                  value={daysOption.filter(function (option) {
                    return option.value === selectedDays;
                  })}
                  onChange={(e) => selectedDayHandler(e.value)}
                ></Select>
              </div>
            </div>
            <div className="">
              <Swiper
                // slidesPerView={2}
                spaceBetween={0}
                navigation
                // autoplay={{ delay: 2000, disableOnInteraction: false }}
                centeredSlides={false}
                modules={[Autoplay, Navigation]}
              >
                {checklist?.map((item) => (
                  <SwiperSlide
                    key={item.checked_id}
                    className="box swiper-check"
                    onClick={(e) => changeCheckList(e, item)}
                    style={{
                      backgroundColor:
                        item?.isChecked === true ? '#623189' : null,
                    }}
                  >
                    <div
                      className="week"
                      style={{
                        color:
                          checkDayOfWeek(item?.check_start_date) === '일'
                            ? '#D30000'
                            : checkDayOfWeek(item?.check_start_date) === '토'
                            ? '#256FFF'
                            : item?.isChecked === true
                            ? '#FFFFFF'
                            : null,
                      }}
                    >
                      {checkDayOfWeek(item?.check_start_date)}
                    </div>
                    <div
                      className="day"
                      style={{
                        color:
                          checkDayOfWeek(item?.check_start_date) === '일'
                            ? '#D30000'
                            : checkDayOfWeek(item?.check_start_date) === '토'
                            ? '#256FFF'
                            : item?.isChecked === true
                            ? '#FFFFFF'
                            : null,
                      }}
                    >
                      {moment(item?.check_start_date).format('DD')}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div className="webpart-item webpart-checklist">
            <h2>일일 체크리스트 상세</h2>
            <section className="checklist-item time">
              <h3 className="checklist-title">방문시간</h3>
              <div className="checklist-content">
                {moment(selectedCheckList?.check_start_date).format(
                  'YYYY/MM/DD hh:mm'
                )}
              </div>
            </section>
            <section className="checklist-item time">
              <h3 className="checklist-title">종료시간</h3>
              <div className="checklist-content">
                {moment(selectedCheckList?.check_end_date).format(
                  'YYYY/MM/DD hh:mm'
                )}
              </div>
            </section>
            <section className="checklist-item range">
              <h3 className="checklist-title">물리적 범위</h3>
              <div className="checklist-content">
                <ul>
                  {physicals?.map((item, index) => (
                    <li>
                      <div className="input-check-zim">
                        <input
                          key={item.name}
                          type="checkbox"
                          id={`inpp0${index}`}
                          checked={item.checked}
                          disabled
                        />
                        <label for={`inpp0${index}`}>{item.name}</label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </section>
            <section className="checklist-item list">
              <h3 className="checklist-title">서비스 항목</h3>
              <div className="checklist-content">
                <ul>
                  {services?.map((item, index) => (
                    <li>
                      <div className="input-check-zim">
                        <input
                          key={item.name}
                          type="checkbox"
                          id={`inps0${index}`}
                          checked={item.checked}
                          disabled
                        />
                        <label for={`inps0${index}`}>{item.name}</label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </section>
            <section className="checklist-item pic">
              <h3 className="checklist-title">완료 사진</h3>
              <div className="checklist-content">
                <ul>
                  {images?.map((item) => (
                    <li onClick={(e) => openModal(e, item)}>
                      <img src={`${baseURL}${item.fileName}`} alt="" />
                    </li>
                  ))}
                </ul>
              </div>
            </section>
            <section className="checklist-item ext">
              <h3 className="checklist-title">현장팀의견</h3>
              <div className="checklist-content">
                <textarea
                  className="textarea"
                  cols="30"
                  rows="10"
                  defaultValue={comment}
                  disabled
                ></textarea>
              </div>
            </section>
            <section className="checklist-item ext">
              <h3 className="checklist-title">고객님의견</h3>
              <div className="checklist-content">
                <textarea
                  className="textarea"
                  cols="30"
                  rows="10"
                  defaultValue={selectedCheckList?.reply_comment}
                  key={selectedCheckList?.reply_comment}
                  onChange={(e) => setCustomerComment(e.target.value)}
                ></textarea>
              </div>
            </section>
            <div className="btn-area">
              <button
                type="button"
                style={{
                  background: '#623189',
                  color: '#ffffff',
                  cursor: 'pointer',
                }}
                onClick={saveCheckList}
              >
                작성
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalOpen ? (
        <ImageInfo closeModal={closeModal} selectedImage={selectedImage} />
      ) : null}
    </>
  );
};

export default CheckListPage;
