import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="inner">
        <div className="footer-logo">Cleanician</div>
        <div className="footer-data">
          <div className="company-data">
            <h2>Info</h2>
            <p className="">
              상호 : 클리니션 | 대표자 : 강민욱
              <br />
              전화번호 : 1670-8801
              <br />
              이메일 : admin@cleanician.com
              <br />
              사업자등록번호 : 394-66-00634
              <br />
              주소1 : 서울 영등포구 버드나루로109, 309호
              <br />
              주소2 : 경기도 김포시 김포한강10로133번길 127, 3층 311-291호
            </p>
            <p className="copy">
              Copyright © 2023.클리니션 All Rights Reserved.
            </p>
          </div>
          <div className="footer-guide">
            <h2>Guide</h2>
            <ul>
              <li>
                <Link to="/regularclinic">정기클리닝</Link>
              </li>
              <li>
                <Link to="/irregularclinic">비정기클리닝</Link>
              </li>
              <li>
                <Link to="/specialclinic">특수클리닝</Link>
              </li>
            </ul>
          </div>
          <div className="footer-contact">
            <h2>Contact US</h2>
            <ul>
              <li>고객센터 : 1670-8801</li>
              <li>상담시간 : 09 ~ 18시</li>
              <li>주말 및 공휴일 Off</li>
            </ul>
          </div>
          <p className="bottom-copy">
            Copyright © 2023.클리니션 All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
