import React from 'react';

const ImageInfo = (props) => {
  const selectedImage = props.selectedImage;
  const baseURL =
    process.env.NODE_ENV === 'development' ? 'http://localhost:8917/' : `/`;

  return (
    <div className="admin-dialog dialog-wrap" onClick={props.closeModal}>
      <div className="dialog-box contract" style={{ padding: '0' }}>
        <div className="admin-dialog-area" style={{ padding: '0' }}>
          <div className="dialog-content">
            <img src={`${baseURL}${selectedImage?.fileName}`} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageInfo;
